import { Notification } from 'element-ui'
import store from '@/store'
import $ from 'jquery'
import router from '@/router';

const FAST_WXCMS_URL = process.env.NODE_ENV === 'development' ? 'https://gateway.qiuxs.cn/wxcms' : (location.origin + '/wxcms');

const timeout = 15000;
const apiSuccessCode = 0;

const DEFAULT_OPTIONS = {
  showError: true
}
class ApiError extends Error {
  constructor(msgId, msg, data) {
    super();
    this.msgId = msgId;
    this.msg = msg;
    this.data = data;
  }
}
class HttpError extends Error {
  constructor(xhr, status) {
    super();
    this.xhr = xhr;
    this.status = status;
  }
}

const showNotification = (title, message, duration) => {
  Notification.error({
    title,
    message,
    duration
  });
}

const showError = (err) => {
  if (err && err.msg) {
    showNotification('错误', err.msg, 3000);
  } else if (err.status === 'timeout') {
    showNotification('错误', '请求超时', 3000);
  } else {
    showNotification('错误', '服务器开小差了,请稍等下重新试试吧', 3000);
  }
}

const redirectToLogin = () => {
  // Notification.error({
  //   message: '会话已过期，请重新登录',
  //   center: true
  // });
  store.commit('userStore/clearUserInfo');
  router.replace('/login');
}

const doApiInner = (host, apiKey, params, jsonParam, options) => {
  return new Promise((resolve, reject) => {
    params = params || {};
    options = options || DEFAULT_OPTIONS;
    if (jsonParam) {
      params.jsonParam = JSON.stringify(jsonParam);
    }
    let sessionId = store.getters['userStore/sessionId'];
    if (sessionId) {
      params.sessionId = sessionId;
    }
    $.ajax({
      url: `${host}/api.do?apiKey=${apiKey}`,
      dataType: 'json',
      data: params,
      timeout: timeout,
      context: http,
      headers: options.headers || {},
      type: 'POST',
      success(data, status) {
        if (status === 'success' && data.code === apiSuccessCode) {
          resolve(data.data);
        } else if (data.code === -4001) {
          redirectToLogin();
        } else {
          if (options.showError) {
            showError(data);
          }
          reject(new ApiError(data.msgId, data.msg, data.data));
        }
      },
      error(xhr, status) {
        if (options.showError) {
          showError({
            status: status
          });
        }
        reject(new HttpError(xhr, status));
      }
    });
  });
}

let BZH_URL;

const http = {
  setBzhUrl(bzhUrl) {
    BZH_URL = bzhUrl;
    sessionStorage.setItem('bzhUrl', bzhUrl);
  },
  getBzhUrl() {
    if (BZH_URL) {
      return BZH_URL;
    }
    BZH_URL = sessionStorage.getItem('bzhUrl');
    return BZH_URL;
  },
  doApi(apiKey, params, jsonParam) {
    return doApiInner(FAST_WXCMS_URL, apiKey, params, jsonParam);
  },
  doApiWithOptions(apiKey, params, jsonParam, options) {
    return doApiInner(FAST_WXCMS_URL, apiKey, params, jsonParam, options || DEFAULT_OPTIONS);
  },
  async doBzhApi(apiKey, params, jsonParam) {
    let signData = await this.doApi("fw-bzh-sign");
    return doApiInner(this.getBzhUrl(), apiKey, params, jsonParam, {
      ...DEFAULT_OPTIONS,
      headers: {
        "ca-sign": signData.sign,
        "ca-appkey": signData.appKey,
        "ca-nonce": signData.nonce,
        "ca-timestamp": signData.timestamp,
      }
    })
  },
  getServerUrl() {
    return FAST_WXCMS_URL;
  }
}

export default {
  install(Vue) {
    Vue.prototype.$http = http;
  }
}

export { FAST_WXCMS_URL }