import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins'
import './directives'
import less from 'less'
import Request from '@/utils/request'
import VueClipboard from 'vue-clipboard2'

Vue.config.productionTip = false

VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard)

Vue.use(less);
Vue.use(Request);

Vue.prototype.$eventBus = new Vue();
Vue.prototype.$eventBus.onAppChange = (callback) => {
  Vue.prototype.$eventBus.$on('currentAppChanged', callback);
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
