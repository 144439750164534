
const USER_KEY = 'userInfo';

let userInfoStr = sessionStorage.getItem(USER_KEY);

let cachedUserInfo = !userInfoStr ? null : JSON.parse(userInfoStr);

export default {
  namespaced: true,
  state: {
    authed: cachedUserInfo != null,
    sessionId: cachedUserInfo != null ? cachedUserInfo.sessionId : null,
    userInfo: cachedUserInfo
  },
  getters: {
    authed(state) {
      return state.authed;
    },
    sessionId(state) {
      return state.sessionId;
    },
    userInfo(state) {
      return state.userInfo || {};
    },
    currentAppId(state) {
      return state.userInfo.unitId;
    },
    currentAppName(state) {
      return state.userInfo.appName;
    }
  },
  mutations: {
    clearUserInfo(state) {
      state.authed = false;
      state.sessionId = null;
      state.userInfo = {};
      sessionStorage.removeItem(USER_KEY);
    },
    setUserInfo(state, data) {
      state.userInfo = data;
      state.sessionId = data.sessionId;
      state.authed = true;
      sessionStorage.setItem(USER_KEY, JSON.stringify(data));
    }
  }
};