const MENU_KEY = 'menuStore';

const filter = (item) => {
  if (item.attr.type === 1 || item.attr.type === 2 || item.attr.type === 3) {
    if (item.children && item.children.length > 0) {
      item.children = item.children.filter(filter);
    }
    return true;
  } else {
    return false;
  }
}

let sessionStoragedMenu = sessionStorage.getItem(MENU_KEY);

let cachedPower = !sessionStoragedMenu ? [] : JSON.parse(sessionStoragedMenu);

let cachedMenu = !cachedPower ? [] : JSON.parse(JSON.stringify(cachedPower)).filter(filter);

export default {
  namespaced: true,
  state: {
    menus: cachedMenu,
    powers: cachedPower
  },
  getters: {
    powers(state) {
      return state.powers;
    },
    menus(state) {
      return state.menus;
    }
  },
  mutations: {
    setMenus(state, menus) {
      state.powers = menus;
      let menusJson = JSON.stringify(menus);
      sessionStorage.setItem(MENU_KEY, menusJson);
      state.menus = JSON.parse(menusJson).filter(filter);
    },
    clearMenuInfo(state) {
      state.powers = [];
      state.menus = [];
      sessionStorage.removeItem(MENU_KEY);
    }
  }
};
