<template>
  <el-autocomplete
    :size="size"
    v-model="caption"
    :fetch-suggestions="fetchSuggestions"
    value-key="caption"
    clearable
    :trigger-on-focus="false"
    @select="handleSelect"
  ></el-autocomplete>
</template>

<script>

export default {
  name: "code-autocomplate",
  props: {
    value: {
      type: Number,
      required: false,
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    allowBlank: {
      type: Boolean,
      required: false,
      default: false,
    },
    codeDomain: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: "mini",
    },
  },
  data() {
    return {
      caption: null,
      code: null,
    };
  },
  methods: {
    handleSelect(item) {
      if (item) {
        this.code = item.code;
      } else {
        this.code = null;
      }
    },
    async fetchSuggestions(queryString, cb) {
      let data = await this.$http.doApi("qd-service-codes", {
        codeDomain: this.codeDomain,
        searchToken: queryString,
        allowBlank: this.allowBlank,
      });
      cb(data.rows);
    },
    async translate() {
      if (this.value) {
        this.code = this.value;
        let data = await this.$http.doApi("qd-service-codes", {
          codeDomain: this.codeDomain,
          code: this.value,
          multiple: this.multiple,
        });
        if (data.rows && data.rows.length > 0) {
          let row = data.rows[0];
          this.caption = row.caption;
        }
      }
    },
  },
  created() {
    this.translate();
  },
  watch: {
    code(newValue) {
      this.$emit("input", newValue);
    },
    caption(newValue) {
      if (!newValue || newValue == null) {
        this.code = null;
      }
    },
  },
};
</script>

<style>
</style>