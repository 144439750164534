<template>
  <div class="common-table-comp">
    <!-- 表格 -->
    <el-table
      :loading="tableData.loading"
      element-loading-spinner="el-icon-loading"
      element-loading-text="拼命加载中..."
      :data="tableData.list"
      :size="size"
      :row-key="rowKey"
      class="common-table"
      :row-class-name="rowClassNameResolver"
      header-row-class-name="common-table-header"
      :expand-row-keys="expandRowKeys"
      @expand-change="onExpandChange"
    >
    
      <el-table-column v-if="expandColumn != null" 
        type="expand"
        :formatter="expandColumn.formatter"
      >
        <template slot-scope="prop">
          <slot name="expand-cell" :row="prop.row" :column="prop.column">    
          </slot>
        </template>
      </el-table-column>

      <el-table-column
        v-if="showIndex"
        align="left"
        label="序号"
        type="index"
      />

      <el-table-column
        v-for="col in simpleColumns"
        :key="col.prop"
        :align="col.align || 'center'"
        header-align="center"
        :prop="col.prop"
        :label="col.label"
        :width="col.width"
        :formatter="getFormatter(col)"
      ></el-table-column>

      <el-table-column
        v-if="options && options.length > 0"
        align="left"
        header-align="center"
        label="操作"
        width="150px"
      >
        <template slot-scope="scope">
          <el-button
            style="padding: 0px"
            v-if="primaryBtn"
            type="text"
            @click="emitHandler(primaryBtn, scope.row)"
            :v-power="primaryBtn.powerId"
            >{{ primaryBtn.label }}</el-button
          >
          &nbsp;
          <el-dropdown
            v-if="otherOptions.length > 0"
            @command="dropdownHandler"
            :v-power="otherPowerIds"
          >
            <span class="el-dropdown-link">
              更多<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="item in otherOptions"
                :key="item.powerId"
                :v-power="item.powerId"
                :command="{
                  btn: item,
                  row: scope.row,
                }"
              >
                {{ item.label }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页工具 -->
    <el-pagination
      class="pagination"
      background
      layout="total, prev, pager, next"
      :current-page="pagination.pageNo"
      :total="tableData.total"
      @current-change="pageChanged"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: "common-table",
  props: {
    columns: {
      type: Array,
      required: true,
    },
    options: {
      type: Array,
      required: false,
    },
    apiKey: {
      type: String,
      required: true,
    },
    autoLoad: {
      type: Boolean,
      required: false,
      default: false,
    },
    searchParams: {
      type: Object,
      required: false,
      default: () => {},
    },
    showIndex: {
      type: Boolean,
      required: false,
      default: true,
    },
    rowKey: {
      type: String,
      required: false,
      default: "id",
    },
    size: {
      type: String,
      required: false,
      default: "mini",
    },
    rowClassNameResolver: {
      type: Function,
      required: false,
      default: ({ row }) => {
        switch (row.flag) {
          case -1:
            return "deleted-row";
          case 0:
            return "disabled-row";
        }
        return "";
      },
    },
    refreshOnAppChange: {
      type: Boolean,
      required: false,
      default: false,
    },
    expandRowKeys: {
      type: Array,
      required: false
    }
  },
  data() {
    return {
      tableData: {
        loading: false,
        list: [],
        total: 0,
      },
      pagination: {
        pageNo: 1,
        pageSize: 10,
      },
    };
  },
  computed: {
    expandColumn: {
      get() {
        let expandColumns = this.columns.filter(item => item.type === 'expand');
        if (expandColumns.length >= 1) {
          return expandColumns[0];
        }
        return null;
      }
    },
    simpleColumns: {
      get() {
        return this.columns.filter(item => item.type !== 'expand');
      }
    },
    primaryBtn: {
      get() {
        return this.options ? this.options[0] : null;
      },
    },
    otherOptions: {
      get() {
        return this.options ? this.options.slice(1, this.options.length) : [];
      },
    },
    otherPowerIds: {
      get() {
        return this.otherOptions.map((item) => item.powerId).join(" || ");
      },
    },
    searchParamsFormatted: {
      get() {
        let params = {};
        for (let key in this.searchParams) {
          if (
            this.searchParams[key] !== null &&
            this.searchParams[key] !== undefined &&
            this.searchParams[key].toString().length > 0
          ) {
            params[key] = this.searchParams[key];
          }
        }
        return params;
      },
    },
  },
  methods: {
    onExpandChange(row, expanded) {
      this.$emit('expand-change', row, expanded);
    },
    getFormatter(col) {
      if (col.formatter) {
        return col.formatter;
      } else if (col.type === "image") {
        return this.imageFormatter;
      }
      return null;
    },
    imageFormatter(row, column, cellVal, index) {
      if (!cellVal) {
        return (<div></div>);
      }
        //"display: inline-block; width: 60px; height: 60px"
      let divStyle = {
          display: 'inline-block',
          width: '50px',
          height: '50px'
      };

      return (
        <el-tooltip
          content="点击查看大图"
          placement={index == 0 ? "bottom" : "top"}
        >
          <div style={divStyle}>
            <el-image
              class="image"
              referrerpolicy="no-referrer"
              preview-src-list={[cellVal]}
              src={cellVal}
              fit="cover"
            />
          </div>
        </el-tooltip>
      );
    },
    async dropdownHandler({ btn, row }) {
      if (btn.handler) {
        btn.handler(row);
      }
    },
    async emitHandler(btn, row) {
      if (btn.handler) {
        btn.handler(row);
      }
    },
    async load(pageNo) {
      try {
        this.tableData.loading = true;
        let data = await this.$http.doApi(
          this.apiKey,
          { pageNo: pageNo, pageSize: this.pagination.pageSize, wrapper: true },
          this.searchParamsFormatted
        );
        this.tableData.list = data.rows;
        this.pagination.pageNo = data.pageNo;
        this.tableData.total = data.total;
      } finally {
        this.tableData.loading = false;
      }
    },
    pageChanged(newPageNo) {
      this.load(newPageNo);
    },
    refreshCurrent() {
      this.load(this.pagination.pageNo);
    },
    refresh() {
      this.load(1);
    },
  },
  created() {
    if (this.autoLoad) {
      this.load(1);
    }
    if (this.refreshOnAppChange) {
      this.$eventBus.$on("currentAppChanged", () => {
        this.load(1);
      });
    }
  },
};
</script>

<style lang="less">
.common-table-comp {
  .common-table {
    width: 100%;

    .el-dropdown-link {
      color: #409eff;
      cursor: pointer;
    }

    .disabled-row {
      background-color: rgb(221, 221, 221);
    }
    .deleted-row {
      background-color: rgb(248, 87, 87);
    }
  }

  .common-table-header {
    color: black;
    th {
      background-color: #f5f5f6;
    }
  }

  .pagination {
    margin-top: 0.8rem;
    text-align: center;
  }
}
</style>