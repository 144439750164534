<template>
  <el-select :size="size" v-model="myValue" clearable>
    <el-option
      v-for="item in options"
      :key="item.code"
      :label="item.caption"
      :value="item.code"
    />
  </el-select>
</template>

<script>
export default {
  name: "code-domain-select",
  props: {
    value: {
      type: [Number, String],
      required: false,
    },
    codeDomain: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: "mini",
    },
  },
  data() {
    return {
      myValue: null,
      options: [],
    };
  },
  methods: {
    async loadOptions() {
      let data = await this.$http.doApi("qd-codes", {
        codeDomain: this.codeDomain,
      });
      this.options = data.rows;
    },
  },
  created() {
    this.loadOptions();
    this.myValue = this.value;
  },
  watch: {
    myValue(newVal) {
      this.$emit("input", newVal);
    },
  },
};
</script>

<style>
</style>