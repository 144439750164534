import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login'),
    meta: {
      title: '登录',
      auth: false
    }
  },
  {
    path: '/register-tenant',
    name: 'TenantRegister',
    component: () => import('@/views/TenantRegister'),
    meta: {
      title: '注册',
      auth: false
    }
  }, {
    path: '/user-protocol',
    name: '使用协议',
    component: () => import('@/views/UserProtocol'),
    meta: {
      title: '使用协议',
      auth: false
    }
  },
  {
    path: '/',
    name: 'Home',
    redirect: '/dashboard',
    component: () => import('@/views/home/Home.vue'),
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import('@/views/home/Dashboard'),
        meta: {
          title: '仪表盘'
        }
      },
      {
        path: '/sys/settings',
        name: 'Settings',
        component: () => import('@/views/home/sys/Settings'),
        meta: {
          title: '系统设置'
        }
      },
      {
        path: '/sys/subusers',
        name: 'SubUsers',
        component: () => import('@/views/home/sys/Subusers'),
        meta: {
          title: '子账户'
        }
      },
      {
        path: '/sys/roles',
        name: 'Roles',
        component: () => import('@/views/home/sys/Roles'),
        meta: {
        }
      }, {
        path: '/wx/fans',
        name: 'WxFans',
        component: () => import('@/views/home/wx/WxFans'),
        meta: {
          title: '微信粉丝'
        }
      },
      {
        path: '/wx/apps/direct',
        name: 'WxAppsDirect',
        component: () => import('@/views/home/wx/Apps/DirectApps'),
        meta: {
          title: '直连应用'
        }
      },
      {
        path: '/wx/apps/authorizer',
        name: 'WxApps',
        component: () => import('@/views/home/wx/Apps/AuthorizerApps'),
        meta: {
          title: '直连应用'
        }
      },
      {
        path: '/wx/autoreply',
        name: 'AutoReply',
        component: () => import('@/views/home/wx/AutoReply'),
        meta: {
          title: '自动回复'
        }
      },
      {
        path: '/wx/pubmenus',
        name: 'PubMenus',
        component: () => import('@/views/home/wx/PubMenus'),
        meta: {
          title: '公众号菜单'
        }
      },
      {
        path: '/wx/material/news',
        name: 'NewsMaterial',
        component: () => import('@/views/home/wx/Material/NewsMaterial'),
        meta: {
          title: '图文素材'
        }
      },
      {
        path: '/wx/material/files',
        name: 'FileMaterial',
        component: () => import('@/views/home/wx/Material/FileMaterial'),
        meta: {
          title: '文件素材'
        }
      },
      {
        path: '/taobao/auth',
        name: 'TaoBaoAuth',
        component: () => import('@/views/home/taobao/TaoBaoAuth'),
        meta: {
          title: '淘宝授权'
        }
      },
      {
        path: '/taobao/applications',
        name: 'TaoBaoApplicationManager',
        component: () => import('@/views/home/taobao/TaoBaoApplicationManager'),
        meta: {
          title: '淘宝应用'
        }
      },
      {
        path: '/taobao/ele/activities',
        name: 'EleActivities',
        component: () => import('@/views/home/taobao/ele/Activities'),
        meta: {
          title: '饿了么活动'
        }
      },
      {
        path: '/sys/manager/datauphold',
        name: 'DataUphold',
        component: () => import('@/views/home/sys/manager/DataUphold'),
        meta: {
          title: '数据维护'
        }
      }, {
        path: '/sys/manager/eventtpl',
        name: 'WxEventHandlerTemplate',
        component: () => import('@/views/home/sys/manager/WxEventHandlerTemplate'),
        meta: {
          title: '微信事件处理模板'
        }
      },
      {
        path: '/sys/manager/wxmp/drafts',
        name: 'MiniProgramDrafts',
        component: () => import('@/views/home/sys/wxmp/Drafts'),
        meta: {
          title: '小程序-草稿箱'
        }
      },
      {
        path: '/sys/manager/wxmp/templates',
        name: 'MiniProgramTemplates',
        component: () => import('@/views/home/sys/wxmp/Templates'),
        meta: {
          title: '小程序-模版管理'
        }
      },
      {
        path: '/application/market',
        name: 'ApplicationMarket',
        component: () => import('@/views/home/application/ApplicationMarket'),
        meta: {
          title: '应用市场'
        }
      },
      {
        path: '/application/detail/:id',
        name: 'ApplicationDetail',
        component: () => import('@/views/home/application/WxApplicationDetail'),
        meta: {
          title: '应用详情'
        }
      }, {
        path: '/cash-orders',
        name: 'CashOrders',
        component: () => import('@/views/home/cash-orders'),
        meta: {
          title: '提现单'
        }
      }, {
        path: '/resources/image',
        name: 'ImageManager',
        component: () => import('@/views/home/resources/ImageManager'),
        meta: {
          title: '图片管理'
        }
      }
    ]
  },
  {
    path: '/wx/auth/callback',
    name: 'WxApplicationAuthCallback',
    component: () => import('@/views/home/wx/ApplicationAuthCallback'),
    meta: {
      title: '应用授权',
      auth: false
    }
  },
  {
    path: '/taobao/auth/callback',
    name: 'TaoBaoAuthCallback',
    component: () => import('@/views/home/taobao/TaoBaoAuthCallback'),
    meta: {
      auth: false,
    }
  },
  {
    path: '/not-found',
    name: 'NotFound',
    component: () => import('@/views/errorpages/404'),
    meta: {
      title: 'Not Found',
      auth: false
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
});

const isAccessDenied = (route) => {
  let powers = store.getters['menuStore/powers'];
  let powerId = route.meta.powerId;
  if (!powerId) {
    return false;
  }
  return powers.indexOf(powerId) === -1;
}

router.beforeEach((to, form, next) => {
  if (to.meta.auth !== false && !store.getters['userStore/authed']) {
    if (form.path === '/login') {
      next(false);
    } else {
      next('/login');
    }
  } else if (isAccessDenied(to)) {
    next('/not-found');
  } else {
    let title = "FastCMS";
    if (to.meta.title) {
      title = title + "-" + to.meta.title;
    }
    document.title = title;
    next();
  }
});

export default router
