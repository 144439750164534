import Vue from 'vue'
import { Aside, Button, Checkbox, Container, Footer, Form, FormItem, Header, Input, Link, Main, Switch, Message, MessageBox, Loading, Popover, Tag, RadioGroup, Radio, DatePicker, Empty, Tooltip, Notification, Table, TableColumn, DropdownMenu, DropdownItem, Dropdown, Select, Option, Pagination, Dialog, InfiniteScroll, Tabs, TabPane, Tree, CheckboxGroup, Autocomplete, ButtonGroup, Upload, Image, Drawer, Card, Row, Col, Breadcrumb, BreadcrumbItem } from 'element-ui'

Message.install = (Vue) => {
  Vue.prototype.$message = Message;
}
MessageBox.install = (Vue) => {
  Vue.prototype.$confirm = MessageBox.confirm;
  Vue.prototype.$alert = MessageBox.alert;
  Vue.prototype.$prompt = MessageBox.prompt;
  Vue.prototype.$msgbox = MessageBox;
}

Notification.install = (Vue) => {
  Vue.prototype.$notify = Notification;
}

Vue.use(Message);
Vue.use(MessageBox);
Vue.use(Loading);
Vue.use(Button)
Vue.use(ButtonGroup)
Vue.use(Container)
Vue.use(Header)
Vue.use(Aside)
Vue.use(Main)
Vue.use(Footer)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Autocomplete)
Vue.use(Select)
Vue.use(Option)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Switch)
Vue.use(Link)
Vue.use(Popover)
Vue.use(Tag)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(DatePicker)
Vue.use(Empty)
Vue.use(Tooltip)
Vue.use(Notification)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Pagination)
Vue.use(Dialog)
Vue.use(InfiniteScroll)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Tree)
Vue.use(Upload)
Vue.use(Image)
Vue.use(Drawer)
Vue.use(Card)
Vue.use(Row)
Vue.use(Col)
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);