import Vue from 'vue';
import store from '@/store'

const powerIds = [];
const pushPowerIds = (powers) => {
  for (let i = 0; i < powers.length; i++) {
    let power = powers[i];
    powerIds.push(power.id);
    let children = power.children;
    if (children && children.length) {
      pushPowerIds(children);
    }
  }
}

const hasPower = (elPowerIds) => {
  if (powerIds.length === 0) {
    const powers = store.getters['menuStore/powers'];
    pushPowerIds(powers);
  }

  if (powerIds.length === 0) {
    return true;
  }

  let hasPower = false;
  for (let i = 0; i < elPowerIds.length; i++) {
    let powerId = elPowerIds[i];
    if (powerIds.indexOf(powerId.trim()) != -1) {
      hasPower = true;
      break;
    }
  }

  return hasPower;
}

Vue.directive('power', {
  inserted(el, binding) {
    let elPowerIds = binding.value.split('||');
    if (!hasPower(elPowerIds) && el.parentNode) {
      el.parentNode.removeChild(el);
    }
  },
  update(el, binding) {
    let elPowerIds = binding.value.split('||');
    if (!hasPower(elPowerIds) && el.parentNode) {
      el.parentNode.removeChild(el);
    }
  }
});